<template>
  <v-container fluid class="content-wrapper" style="overflow-y: hidden">
    <!-- <div
      class="overflow-y-auto"
      ref="payoutContainer"
    >-->
    <div class="content-body">
      <!-- Products sell and New Orders -->
      <div class="row match-height">
        <div class="col-12 d-flex flex-row justify-start align-center">
          <h4 class="fw-600 mb-2">{{ $t("SigninPayout.title") }}</h4>
          <v-btn
            icon
            class="ml-3 mb-2"
            @click="initPayoutDataFunc()"
            :class="loadingPayoutData && 'animate__spining'"
            :disabled="loadingPayoutData"
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </div>
      </div>

      <div class="row pr-1">
        <div class="col-md-12 pt-2">
          <div class="row">
            <div class="col-md-2 col-sm-6 col-12">
              <v-select
                v-model="select_filter_year"
                :items="itemsFilterYear"
                label
                :placeholder="$t('SignupStep.select_one')"
                :no-data-text="$t('SignupStep.nodata')"
                color="#1e9ff2"
                class="font-14px"
                solo
                flat
                dense
                single-line
                hide-details
                @change="selectFilterFunc()"
              >
                <template v-slot:item="data">
                  <v-list-item-content
                    @mouseenter="getfocus('select_filter_year')"
                    @mouseleave="mouseOver('select_filter_year')"
                  >
                    <v-list-item-title
                      v-html="data.item"
                      class="title-signup-sj font-14px"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </div>
            <div class="col-md-4 col-sm-6 col-12">
              <button
                class="btn btn-outline-primary"
                style="min-height: 3rem; max-height: 4rem; height: 100%"
                @click="getPDFFileFunc()"
              >
                {{ $t("SigninPayout.download_all_statement") }}
                {{ select_filter_year }} {{ $t("SigninPayout.all_statement") }}
              </button>
            </div>
            <div class="offset-md-3 col-md-3 col-sm-6 col-12">
              <div
                class="text-center py-1 bg-white d-flex align-center justify-center"
                style="min-height: 3rem; max-height: 4rem; height: 100%"
              >
                <span class>
                  {{ $t("SigninPayout.label_ur_next_payout") }}
                  <v-icon class="mx-2" small>mdi-information-outline</v-icon>
                  {{ last_updated }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="loadingPayoutData" class="mt-1">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </div>

      <v-row v-else v-for="month in monthDataItems" :key="month.month_year" class="mt-1">
        <div class="row" style="padding-bottom: 50px;">
          <div class="col-12">
						<h4 class="card-title">{{ month.month_name }}</h4>
					</div>
					<div class="col-12">
						<ul id="accordion" class="cursor-pointer border pl-0">
							<li>
								<div class="table-responsive">
									<table class="table table-borderless table-xl mb-0" width="100%">
										
										<tbody>
											<tr class="bg-white row-parent">
												<td class="pl-1" width="15%">
													<h6>
														<i
                              :id="`iupanddopwn`+month.key"
                              @click="showOrhideDataTAbleViaID(month.key)"
                              class="v-icon notranslate mdi mdi-chevron-down">
                            </i>
														<b>{{ month.is_current }}</b>
													</h6>
												</td>
												<td width="15%">
													
												</td>
												<td colspan="3" width="15%">
													
												</td>
												<td class="text-center" colspan="2" width="25%">
													<h6>
														<i class="ft-paperclip"></i>
														Download statement
														<span class="info">PDF</span> | <span class="info">CSV</span>
													</h6>
												</td>
												<td class=" text-right px-1" width="15%">
													<h6><b>THB {{ month.total }}</b></h6>
												</td>
                        <td class="text-right px-1" width="15%">
													<button v-if="!checkWithDraw(month)" class="btn btn-sm btn-success" @click="approveAction(month)"> Approve</button>
												</td>
											</tr>
										</tbody>
									</table>

                  <v-data-table
                        :id="month.key"
                        :headers="headersMonthData"
                        :items="month.month_items"
                        hide-default-footer
                        class="elevation-0"
                        :single-expand="singleExpand"
                        :expanded.sync="month.expanded"
                        item-key="month_year"
                        :no-data-text="$t('RateAndInventory.nodata')"
                        :item-class="itemRowBackground"
                        style="display: none"
                      >
                      <template slot="body.append">
                        <tr style="background-color:silver;">
                          <td colspan="2" style="font-size: 20px;" class="text-right bold">Total</td>
                          <td style="font-size: 18px;" class="bold">{{ month.total_customer_paid }}</td>
                          <td style="font-size: 18px;" class="bold">{{ month.total_aigent_paid }}</td> 
                          <td style="font-size: 18px;" class="bold">{{ month.total_loyalty }}</td>
                          <td style="font-size: 18px;" class="bold">{{ month.total }}</td>
                          <td colspan="2"></td>
                        </tr>
                      </template>
                    </v-data-table>
								</div>
							</li>
						</ul>
					</div>
				</div>
      </v-row>
      <v-row v-if="!loadingPayoutData && !monthDataItems.length" class="mt-1 pb-3 px-4">
        <v-col cols="12" sm="12" md="12" lg="12" class="bg-white">
          <span>
            {{ $t("SigninPayout.no_payout_data") }}
          </span>
        </v-col>
      </v-row>
    </div>
    <!-- </div> -->
  </v-container>
</template>

<script>
import { EventBus } from "@/EventBus";
import swal from "sweetalert";
import i18n from "@/plugins/i18n";
import moment from "moment";
moment.locale(i18n.locale);

export default {
  name: "SigninPayoutScreen",
  metaInfo: {
    title: "Payout Information",
    titleTemplate: "%s | Aigent",
  },
  data: () => ({
    userToken: "",
    propertyID: "",
    value_element: "",
    select_filter_year: null,
    last_updated: null,
    monthDataItems: [],
    headersMonthData: [],
    expanded: [],
    singleExpand: false,
    payoutItems: null,
    loadingPayoutData: false,
    itemsFilterYear: [2021, 2022, 2023],
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;

    // self.last_updated = moment().format("DD MMM YYYY");
    self.select_filter_year = Number(moment().format("YYYY"));
    self.initAll();
    console.log("self.select_filter_year", self.select_filter_year);
  },
  methods: {
    checkWithDraw(data){
        // get current month and year
        // const month = moment().format("MMMM");
        // const year = moment().format("YYYY");

        // // loop data is this month
        // if(data?.month_name == month && data?.month_year == year) {
        //   return true
        // } else {
        //   // loop data check withdraw is true or false
        //   var tmp = false;
        //   data?.month_items?.map((el) => {
        //     if (el?.withdraw) tmp = true;
        //   });
        //   return tmp;
      // }
      return false;
    },
    async approveAction(data) {
      // swal confirm
      const self = this;
      swal({
        title: "Are you sure?",
        text: "Once approved, you will not be able to recover this data!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          const self = this;
          // console.log("data: ", data);
          // encrypt data via sha256
          const sha256 = require('crypto');
          const hash = sha256.createHash('sha256');
          hash.update(data?.key_approve + self?.propertyID);
          const hashData = hash.digest('hex');

          // call api
          try {
            // https://aigent-admin.getdev.top/api/payout/info
            await self.axios.post(
              process.env.VUE_APP_AIGENT_API + `/payout/approve`,
              {
                id: self.propertyID,
                data: data,
                key_request: hashData,
              }
            );
          } catch (error) {
            console.log(error?.response?.data?.message || error);
            swal(
              "Warning",
              error?.response?.data?.message || "Please try again",
              "warning",
              {
                button: false,
                timer: 3000,
              }
            );
          } finally {
            self.initPayoutDataFunc();
          }
        }
      });
    },
    showOrhideDataTAbleViaID(key, k) {
      console.log("key: ", key);
      console.log("k: ", k);
      // get v-data-table via id
      const table = document.getElementById(key);
      
      // show or hide v-data-table
      if (table.style.display === "none") {
        table.style.display = "block";
        $('#iupanddopwn' + key).removeClass('mdi-chevron-down').addClass('mdi-chevron-up');
      } else {
        table.style.display = "none";
        $('#iupanddopwn' + key).removeClass('mdi-chevron-up').addClass('mdi-chevron-down');
      }
    },
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("focus-2");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("focus-2");
        element.classList.add("focus-2");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("focus-2");
        element.classList.remove("focus-2");
        this.value_element = "";
      }
    },
    priceFormatter(value) {
      return Number(value || 0)
        ?.toFixed(2)
        ?.replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    initAll() {
      const self = this;
      self.headersMonthData = [
        {
          text: "Voucher/Transaction ID",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Type",
          align: "start",
          sortable: true,
          value: "type",
        },
        {
          text: "Customer Paid",
          align: "start",
          sortable: true,
          value: "customer_paid",
        },
        {
          text: "Aigent Paid",
          align: "start",
          sortable: true,
          value: "aigent_paid",
        },
        {
          text: "Loyalty",
          align: "start",
          sortable: true,
          value: "loyalty",
        },
        {
          text: "Amount",
          align: "start",
          sortable: true,
          value: "amount",
        },
        {
          text: "Status",
          align: "start",
          sortable: true,
          value: "status",
        },
        {
          text: "Date",
          align: "start",
          sortable: true,
          value: "updated_at",
        },
      ];

      self.initPayoutDataFunc();
    },
    async initPayoutDataFunc() {
      const self = this;
      self.loadingPayoutData = true;
      var temp = null;
      var tmpArray = [];
      EventBus.$emit("loadingtillend");
      try {
        // https://aigent-admin.getdev.top/api/payout/info
        const res = await self.axios.post(
          process.env.VUE_APP_AIGENT_API + `/payout/info`,
          {
            id: self.propertyID,
            year: self.select_filter_year,
          }
        );
        if (res.status == 200) {
          console.log("payout: ", res);
          if (res?.data?.data) {
            temp = res?.data?.data || null;
          }
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.loadingPayoutData = false;
        self.payoutItems = temp;
        self.monthDataItems = temp;
        console.log("payoutItems: ", self.monthDataItems);
      }
    },
    copyIDData(id) {
      var copyText = id;
      navigator.clipboard.writeText(copyText);
    },
    clickRow(item, event) {
      // console.log("row: ", item, event)
      // item.expanded = []
      if (event.isExpanded) {
        const index = this.expanded?.findIndex((i) => i === item);
        this.expanded?.splice(index, 1);
      } else {
        if (item?.header?.transaction_omise)
          this.expanded?.push({
            ...item,
            _id: item?.header?.transaction_omise,
          });
      }
      // console.log("row: ", item, item?.month_items, event, this.expanded);
    },
    checkRowExpanded(item) {
      const self = this;
      var tmp = false;
      self.expanded?.map((el) => {
        if (el?._id == item._id) tmp = true;
      });
      return tmp;
    },
    itemRowBackground(item) {
      const self = this;
      // console.log(item)
      var tmp = "";
      self.expanded?.map((el) => {
        if (el?._id == item._id) tmp = "show-expanded-item-rateplan";
      });
      return tmp;
    },
    checkPurchaseDateFunc(date) {
      const str1 = date?.split("/")[0];
      const str2 = date?.split("/")[1];
      const str3 = date?.split("/")[2];
      const str = `${str2}-${str1}-${str3}`;
      // date?.replaceAll("/", "-")
      // console.log(date, str1, str2, str3, new Date(str3, str2, str1), date?.replaceAll("/", "-"), moment.utc(date?.replaceAll("/", "-")).format("YYYY-MM-DD"), moment.utc(str, 'MM-DD-YYYY').format('YYYY-MM-DD'))
      return (
        // moment(date?.replaceAll("/", "-")).format("DD MMM YYYY") || moment(new Date(str3, str2, str1)).format("DD MMM YYYY") ||
        moment(str).format("DD MMM YYYY") || this.$t("SignupStep.nodata")
      );
    },
    checkDateRangeFunc(data) {
      return data?.header?.day
        ? data?.header?.day
        : this.$t("SignupStep.nodata");
    },
    checkPayoutDateFunc(data) {
      return data?.header?.payout_issued || this.$t("SignupStep.nodata");
    },
    selectFilterFunc() {
      this.initPayoutDataFunc();
    },
    checkBodyTotalAmountFunc(item) {
      const sum = item?.reduce((sum, number) => sum + number?.amount, 0) || 0;
      return this.priceFormatter(sum) || "0.00";
    },
    checkBodyTotalGroupDiscountFunc(item) {
      const sum =
        item?.reduce((sum, number) => sum + number?.group_discount, 0) || 0;
      return sum;
    },
    checkBodyTotalLoyaltyFeeFunc(item) {
      const sum =
        item?.reduce((sum, number) => sum + number?.loyalty_fee, 0) || 0;
      return sum;
    },
    checkBodyTotalNetFunc(item) {
      const sum = item?.reduce((sum, number) => sum + number?.net, 0) || 0;
      return this.priceFormatter(sum) || "0.00";
    },
    checkBodyTotalPaymentFeeFunc(item) {
      const sum =
        item?.reduce((sum, number) => sum + number?.payment_fee, 0) || 0;
      return this.priceFormatter(sum) || "0.00";
    },
    async getPDFFileFunc() {
      const self = this;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/payout/gen_pdf_payout?voucher_id=${self.propertyID}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
            responseType: "blob",
          }
        );
        if (res.status == 200) {
          console.log(res);
          window.open(URL.createObjectURL(res.data));
          EventBus.$emit("endloading");
        } else {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          "Warning",
          error?.response?.data?.message || "Please try again",
          "warning",
          {
            button: false,
            timer: 3000,
          }
        );
      } finally {
        EventBus.$emit("endloading");
      }
    },
  },
};
</script>

<style scoped>
@import "../../../styles/signin/holiday.css";
@import "../../../styles/signin/rateplan.css";
@import "../../../styles/signin/finance.css";
</style>

<style lang="scss" scoped>
@import "../../../styles/bootstrap/global.css";
@import "../../../styles/signin/setting.css";
@import "../../../styles/bootstrap/bootstrap.css";
@import "../../../styles/bootstrap/colors.css";

.elevation-0 {
  padding-bottom: 15px !important;
}
.border-pink-focus {
  border-color: var(--pink) !important;
}
:root {
  --pink:#e8107f;
  --purple:#262262;
}

html body {
  font-family: "Quicksand", Georgia, "Times New Roman", Times, serif;
}

.gray {
  color: var(--gray);
}

.btn-tooltip {
  width: 25px;
  height: 25px;
  background-color: transparent;
  border-radius: 50%;
  padding: 0;
  color: #000;
}

.btn-tooltip .la {
  font-size: 22px;
}

.btn-actions {
  padding: 0;
}

.btn-actions:after {
  display: none;
}

.btn-actions .la {
  font-size: 24px;
}

.table.align-middle td {
  vertical-align: middle;
}

html body.fixed-navbar {
  padding-top: 6rem;
}

.content .content-wrapper {
  overflow: hidden;
}

body.vertical-layout.vertical-menu.menu-collapsed .navbar .navbar-brand p {
  -webkit-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadeout;
          animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadeout;
}

body.vertical-layout.vertical-menu.menu-collapsed .navbar-header .navbar-brand .wrap {
  -webkit-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadeout;
          animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadeout;
  width: 0;
}

.status-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 5px;
}

.main-menu.menu-dark,
.main-menu.menu-dark .navigation {
  background: #0a1a2a;
  z-index: 10002;
}

.main-menu.menu-dark .navigation > li.open > a {
  border-right: 4px solid var(--pink);
  background-color: #142536;
}

.navbar-header .navbar-brand {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  background-color: #08121e;
}

.navbar-header .navbar-brand .wrap {
  padding-left: 8px;
}

.navbar-header .navbar-brand p {
  color: #fff;
  font-size: 10px;
}

.header-navbar .navbar-header {
  height: auto;
  background-color: #08121e;
}

.header-navbar .navbar-header .navbar-brand .brand-text {
  padding-left: 0;
}

.main-menu.menu-dark .navigation > li.has-sub.open ul {
  background-color: #142536;
}

.card.h-100 {
  height: calc(100% - 25px) !important;
}

.card.h-100 .card-content {
  height: calc(100% - 60px);
}

.card.h-100 .card-body {
  height: calc(100% - 60px);
}

.card .h-chart-100 {
  height: calc(100% - 60px);
}

.legend-row > div {
  margin: 20px 0;
  border-right: 1px solid #f2f2f2;
}

.legend-row > div:last-child {
  border-right: 0;
}

.nav.chart-action .nav-item {
  padding: 0 5px;
}

.nav.chart-action .nav-item .nav-link {
  position: relative;
  color: #ccc;
}

.nav.chart-action .nav-item .nav-link:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  border-bottom: 3px solid #e96d2f;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.nav.chart-action .nav-item .nav-link.active {
  color: #000;
}

.nav.chart-action .nav-item .nav-link.active:before {
  width: 100%;
}

.btn.btn-icons {
  color: #fff;
  border-radius: 50%;
  padding: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-top: 3px;
}

.btn.btn-icons .la {
  font-size: 20px;
}

@media (min-width: 1441px) {
  .col-xxl-4 {
    padding: 0 15px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xxl-8 {
    padding: 0 15px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xxl-9 {
    padding: 0 15px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-3 {
    padding: 0 15px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (max-width: 767px) {
  .header-navbar {
    min-height: 0;
  }
  .header-navbar .navbar-header {
    padding: 20px 20px;
  }
  .header-navbar .navbar-header .navbar-brand {
    padding: 0;
    top: 19px;
  }
  .navbar-header .navbar-brand p {
    margin-bottom: 0;
  }
  .vertical-overlay-menu .header-navbar .navbar-header li.nav-item.d-md-none a.open-navbar-container,
  .vertical-overlay-menu .header-navbar .navbar-header .mobile-menu a.menu-toggle {
    padding: 0;
  }
  .header-navbar .navbar-header .navbar-brand .brand-text {
    margin-top: -2px;
    display: block;
    margin-bottom: 2px;
  }
  .navbar-semi-dark .navbar-nav li {
    line-height: unset;
  }
  h2, .h2 {
    font-size: 1.44rem;
  }
  h3, .h3 {
    font-size: 1.25rem;
  }
  #world-map-markers.height-450 {
    height: 250px !important;
  }
  .btn.btn-icons {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
  .navbar-property-name {
    margin: 0;
    padding: 0 0 15px;
    display: block;
  }
  .navbar-property-name .nav-item {
    float: none !important;
    text-align: center;
    background-color: #f2f2f2;
    border-radius: 5px;
    padding: 4px;
  }
}

.btn {
  min-height: 42 !important;
  height: auto !important;
  line-height: 1.25 !important;
  padding: 0.75rem 1rem !important;
  text-transform: none !important;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.mt-27px {
  margin-top: 27px;
}

.mt-20px {
  margin-top: 20px;
}

.ml-20px {
  margin-left: 20px;
}

.mr-112px {
  margin-right: 112px;
}

.mr-3px {
  margin-right: 3px;
}

.mt-10px {
  margin-top: 10px;
}

.calandar-mini {
  border-radius: 5px;
  border: 1px solid #636363;
  overflow: auto;
}

.calandar-mini .title {
  font-size: 18px;
  padding: 10px 0px;
  text-align: center;
}

.calandar-mini table {
  margin-bottom: 0px;
}

.calandar-mini table thead tr th {
  width: 14.285%;
  padding: 5px 0px;
  font-size: 10px;
  text-align: center;
  background-color: #1e9ff2;
  color: white;
}

.calandar-mini table tbody tr td {
  padding: 2px;
  height: 60px;
  font-size: 10px;
  position: relative;
  cursor: pointer;
}

.calandar-mini table tbody tr td:hover {
  background-color: rgba(28, 160, 242, 0.3);
}

.calandar-mini table tbody tr td .noti-holiday {
  position: absolute;
  bottom: 4px;
  right: 4px;
  height: 10px;
  width: 10px;
  background-color: #e8107f;
  border-radius: 50%;
}

.custom-icon-camera {
  background-color: #666ee8 !important;
  border-radius: 20px !important;
  padding: 2px !important;
  border-color: unset;
}

.custom-icon-camera .la-camera {
  color: #fff;
}

.fs-20px {
  font-size: 20px !important;
}

.fs-25px {
  font-size: 25px !important;
}

.select-group {
  background-color: #e9ecef;
  border-radius: 0px 5px 5px 0px;
}

.custom-box-policy {
  position: relative;
  padding-bottom: 70px !important;
}

.custom-box-policy .btn-edit-policy {
  position: absolute;
  bottom: 20px;
}

.btn-primary:hover {
  border-color: #7c83eb !important;
  background-color: #7c83eb !important;
  color: #FFF !important;
}

.btn-secondary:hover {
  border-color: #777b8f !important;
  background-color: #777b8f !important;
  color: #FFF !important;
}

.modal-header {
  background-color: white !important;
  border-top-left-radius: 0.35rem !important;
  border-top-right-radius: 0.35rem !important;
}

.modal-body {
  border-radius: 0 !important;
  background-color: #fff !important;
  padding: 1rem !important;
}

.modal-content {
  background-color: white !important;
}

.modal-footer {
  border-radius: 0 !important;
}

.btn-outline-primary {
  border-color: #666ee8 !important;
  background-color: transparent !important;
  color: #666ee8 !important;
}

.btn-outline-primary:hover {
  background-color: #666ee8 !important;
  border-color: #666ee8 !important;
  color: #FFF !important;
}

.btn-outline-secondary {
  border-color: #6b6f82 !important;
  background-color: transparent !important;
  color: #6b6f82 !important;
}

.btn-outline-secondary:hover {
  background-color: #6b6f82 !important;
  border-color: #6b6f82 !important;
  color: #FFF !important;
}

.form-control {
  -webkit-appearance: auto !important;
  border: 1px solid #babfc7 !important;
  height: 40.5px !important;
}

.form-control.textarea {
  -webkit-appearance: auto !important;
  border: 1px solid #babfc7 !important;
  height: auto !important;
}

.form-control:focus {
  color: #4e5154 !important;
  background-color: #fff !important;
  border-color: #1e9ff2 !important;
  outline: 0 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.btn-warning:hover {
  border-color: #ffa063 !important;
  background-color: #ffa063 !important;
  color: #FFF !important;
}

.btn-light:hover {
  border-color: #bdbdbd !important;
  background-color: #bdbdbd !important;
  color: #FFF !important;
}

.btn.w-100 {
  border-radius: 0.25rem !important;
}

.btn-danger:hover {
  border-color: #ff6377 !important;
  background-color: #ff6377 !important;
  color: #FFF !important;
}

.btn-info:hover {
  border-color: #36a9f3 !important;
  background-color: #36a9f3 !important;
  color: #FFF !important;
}

.btn-close, .btn-close:hover {
  background-color: transparent !important;
  border: transparent !important;
  color: #6b6f82;
}

.btn span {
  display: inline-block !important;
}

.form-control.br-rb-unset {
  border-radius: 5px 0px 0px 5px !important;
}

.form-control.br-lt-unset {
  border-radius: 0px 5px 5px 0px !important;
}

.custom-select {
  display: inline-block !important;
  width: 100% !important;
  height: calc(1.25em + 1.5rem + 2px) !important;
  padding: 0.75rem 2rem 0.75rem 1rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.25 !important;
  color: #4e5154 !important;
  vertical-align: middle !important;
  background-color: #fff !important;
  border: 1px solid #babfc7 !important;
  border-radius: 0.25rem !important;
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23464855' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px !important;
  position: relative !important;
  top: unset !important;
  left: unset !important;
  right: unset !important;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.5rem 0.75rem !important;
  font-size: 0.875rem !important;
  line-height: 1 !important;
  border-radius: 0.21rem !important;
}

label.activity {
  font-size: 14px;
  line-height: 32px;
}

.custom-btn-img {
  border-radius: 50%;
  height: 30px !important;
  width: 30px !important;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 3px;
  margin-top: 5px;
}

.container-fluid {
  padding-left: 15px;
  padding-right: 15px;
}

.w-125px {
  width: 125px !important;
}

.min-w-125px {
  min-width: 125px !important;
}

/* backdround color */
.bg-grey {
  background-color: #f4f4fd;
}

.list-group-item {
  padding: 10px;
}

.hover-grey:hover {
  /* transform: translateY(1px); */
  box-shadow: 0 0 10px 0 rgb(58 70 93 / 25%);
  transition: all .2s;
  background-color: rgb(229 230 232 / 50%) !important;
  cursor: pointer;
}

.bg-grey-dark {
  background-color: rgb(170 170 170 / 50%) !important;
}

.bg-grey-dark:hover {
  box-shadow: 0 0 10px 0 rgb(58 70 93 / 25%);
  transition: all .2s;
  background-color: rgb(170 170 170 / 50%) !important;
  cursor: pointer;
}

.border-grey-rate {
  border-bottom: 1px solid #E4E5EC;
  border-right: 1px solid #E4E5EC;
  border-left: 1px solid #E4E5EC;
}

.btn-center {
  position: absolute;
  bottom: 10px;
}

.timeline-item b.text-white {
  font-size: 16px;
  position: relative;
  top: 5px;
  align-self: center;
}

/* table */ 
.table-scroll {
  position: relative;
  width:100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  height: 550px;
}
.table-scroll table {
  width: 100%;
  min-width: 1050px;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}
.table-wrap {
  position: relative;
}
.table-scroll th,
.table-scroll td {
  padding: 5px 10px;
  /* background: #fff; */
  vertical-align: top;
}
.table-scroll thead th {
  position: sticky;
  top: 0;
}
/* safari and ios need the tfoot itself to be position:sticky also */
.table-scroll tfoot,
.table-scroll tfoot th,
.table-scroll tfoot td {
  position: sticky;
  bottom: 0;
  z-index:4;
}

/* th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
} */
thead th:first-child,
tfoot th:first-child {
  z-index: 5;
}

.table-scroll th, .table td  {
  border-top: 1px solid #eee;
}

table.dataTable thead th, table.dataTable thead td {
  padding: 10px 0px !important;
  border-bottom: 1px solid #111;
}

#voucher-list th, #voucher-list td {
  padding: 1.25rem 1rem;
}

#voucher-list td.fit, 
#voucher-list th.fit {
    white-space: nowrap;
    width: 1%;
}

/* select */

.bootstrap-select .btn {
  background-color: #fff!important;
  border-color: #fff!important;
  color: #000 !important;
}

/* select multiple */
.bootstrap-select .dropdown-toggle:focus, 
.bootstrap-select>select.mobile-device:focus+.dropdown-toggle {
    outline: 0px !important;
    outline-offset: -2px;
}

.bootstrap-select .dropdown-toggle {
  border: 1px solid #babfc7 !important;
}

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  overflow: hidden;
  text-transform: none !important;
  color: #3b4781 !important;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #1e9ff2;
}

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  top: 50% !important;
  right: 10px !important;
}

.card.box-shadow {
  box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%) !important;
}

.box-boder {
  border: 1px solid #eee;
}

ul {
  list-style-type: none;
}

.li-bottom {
  border-bottom: 1px solid #4a4a4a;
}

.accordion-list ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 0rem;
}

.card-accordion {
  border: 1px solid #4a4a4a;
  padding: 0px 10px !important;
  margin: 16px 0px;
  border-radius: 4px;
}
.accordion {
  width: 100%;
  padding: 0;
}

.accordion .link {
  padding: 14px;
  cursor: pointer;
  display: block;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  text-align: left;
  font-size: 16px;
}

.accordion li:last-child .link {
  border-bottom: 0;
}

.accordion li i {
  position: absolute;
  top: 20px;
  left: 12px;
  font-size: 18px;
  color: #595959;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.accordion li i {
  right: 12px;
  left: auto;
  font-size: 16px;
}

.accordion li.open i {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

/**
 * Submenu
 -----------------------------*/

.submenu {
  display: none;
  font-size: 14px;
  padding: 0px 18px;
}

.submenu a {
  display: block;
  text-decoration: none;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.border-bottom {
  display: block;
  border-bottom: 1px solid #cccccc!important;
}

.menu-content .menu-item.active {
  font-weight: 700;
  background: #424e58 !important;
  margin: 0 1rem 0 1rem;
  border-radius: 0.3rem;
  padding-left:40px !important;
}

.textarea-light {
  height: 65px !important;
  border: 1px solid #eeeeee !important;
}

.box-inbox-list {
  height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
}

.box-inbox {
  height: 540px;
  overflow-y: auto;
  overflow-x: hidden;
}

.box-inbox-input {
  height: 125px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.over-text {
  max-width: 33vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sidebar-left {
  position: relative;
  background: #fff !important;
  z-index: 2;
  left: 1px;
}

.sidebar-close-icon {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
  font-size: 1.25rem;
  z-index: 5;
}

.sidebar-close-icon-2 {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
  font-size: 1.25rem;
  z-index: 5;
}

.info {
  color: #fff;
}

.v-application .info {
  background-color: #fff !important;
}
</style>
